<template>
  <div class="data-posts">
    <div class="data-posts-left">
      <el-form>
        <el-form-item>
          <el-input
            size="small"
            v-model="searchForm.search"
            clearable
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="searchForm.user"
            placeholder="请输入用户ID"
          ></el-input>
        </el-form-item>
        <!--  <el-form-item>
          <el-input size="small" placeholder="请输入用户昵称"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button
            class="el-form-btn"
            type="primary"
            size="small"
            @click="searchFun"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <logCom
        :logList="logList"
        title="近10条查询记录"
        height="calc(100% - 120px)"
        attr="search"
        @selectLog="selectLog"
        ref="postslogCom"
      />
    </div>
    <div class="data-posts-right" v-loading="postsLoading">
      <totalAll v-if="list.length > 0" :text="'共检索到数据' + total + '条'" />

      <div
        class="child-box"
        v-infinite-scroll="load"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="right-item" v-for="(item, i) in list" :key="i">
          <div class="right-item-title">
            <div class="title-left">
              <img
                :src="
                  item.user
                    ? item.user.avatar
                      ? item.user.avatar
                      : require('@/assets/imgs/p2.png')
                    : require('@/assets/imgs/p2.png')
                "
                alt=""
              />
              <span>
                {{
                  item.user
                    ? item.user.nick_name
                      ? item.user.nick_name
                      : item.user.user_name
                    : '未知'
                }}
              </span>
              &nbsp;
              <span>{{ item.create_time | capitalize }}</span>
            </div>
            <div class="title-right">帖子ID：{{ item.story_fbid }}</div>
          </div>
          <div class="right-item-content" v-html="item.content_html"></div>
          <div v-if="item.media" class="right-item-media">
            <img
              :src="imgItem"
              v-for="(imgItem, i) in item.media"
              :key="i"
              alt=""
              :onerror="noPic"
            />
          </div>
        </div>
        <noMorePage :nomore="nomore" v-if="list.length > 0" />
      </div>

      <noData ref="noDataCom" v-show="list.length == 0" />
    </div>
  </div>
</template>

<script>
import { articlesList } from '@/api/data.js'
import logCom from '../components/LogCom.vue'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      searchForm: {
        search: '',
        user: ''
      },
      logList: [], //记录
      list: [],
      current: 1,
      pageSize: 40,
      nomore: true,
      total: 0,
      scrollDisabled: false,
      postsLoading: false
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''

      return value.split('+')[0]
    }
  },
  components: {
    logCom
  },

  created() {
    this.getLocalStorageFun()
    // console.log('...........')
    // console.log(this.$route.params.user)
    this.searchForm.user = this.$route.params.user
  },
  methods: {
    // 查询
    searchFun() {
      this.current = 1
      this.list = []
      this.load()
      this.nomore = false
      this.$refs.postslogCom.selectIndex = null
      this.localStorageFun()
      this.getLocalStorageFun()
    },
    // 记录
    selectLog(row) {
      this.searchForm = Object.assign({}, row)
      this.current = 1
      this.list = []
      this.load()
      this.nomore = false

      this.getLocalStorageFun()
    },

    load() {
      // this.postsLoading = true
      let obj = {
        search: this.searchForm.search,
        user: this.searchForm.user,
        page: this.current,
        page_size: this.pageSize
      }
      this.postsLoading = true
      this.scrollDisabled = true
      articlesList(obj).then(res => {
        if (res) {
          this.total = res.data.count
          if (res.data.data.length > 0) {
            this.scrollDisabled = false
            this.current++
            this.list = this.list.concat(res.data.data)
            if (res.data.data.length < this.pageSize) {
              this.nomore = false
            }
          } else {
            this.nomore = false
          }
        } else {
          this.nomore = false
        }
        this.postsLoading = false
      })
    },
    // 左侧记录
    localStorageFun() {
      let arrString = localStorage.getItem('facebookPostsCommunity')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        if (arr.length < 10) {
          if (this.isEmpty(this.searchForm)) {
            arr.unshift(this.searchForm)
          }
        } else {
          if (this.isEmpty(this.searchForm)) {
            arr.unshift(this.searchForm)
          }
        }
      } else {
        arr = []
        if (this.isEmpty(this.searchForm)) {
          arr.unshift(this.searchForm)
        }
      }
      localStorage.setItem('facebookPostsCommunity', JSON.stringify(arr))
    },
    getLocalStorageFun() {
      let arrString = localStorage.getItem('facebookPostsCommunity')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        this.logList = [].concat(arr)
      }
    },
    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (obj[i] && obj[i].trim() !== '') {
          arr.push(obj[i])
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss">
.data-posts {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .data-posts-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-btn {
      width: 100%;
      border: 0px solid #000000;
      border-radius: 16px;
    }
  }
  .data-posts-right {
    width: calc(100% - 270px);
    height: 100%;
    position: relative;
    .child-box {
      width: 100%;
      height: calc(100% - 40px);
      overflow: hidden;
      overflow-y: auto;
      margin-top: 10px;
    }
    .right-item {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 0px 10px 0px rgba(124, 124, 124, 0.2);
      border-radius: 5px;
      padding: 10px 20px;
      // overflow: hidden;
      margin-bottom: 13px;
      .right-item-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        .title-left {
          align-self: center;
          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 13px;
          }
          span {
            font-size: 12px;
            vertical-align: middle;
            font-weight: 300;
            color: #1a1a1b;
          }
        }
        .title-right {
          font-size: 12px;
          font-weight: 300;
          color: #898989;
          align-self: center;
        }
      }
      .right-item-content {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 300;
        color: #1a1a1b;
        line-height: 24px;
        padding: 5px 0;
      }
      .right-item-media {
        margin-top: 20px;
        img {
          width: 150px;
          height: 200px;
          object-fit: cover;
          margin-right: 13px;
        }
      }
    }
  }
}
.comment-nomore {
  font-size: 14px;
  color: #606060;
  text-align: center;
  list-style: none;
}
</style>
